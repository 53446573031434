<template>
  <div
    class="card card-custom grid-stack-item-content"
    style="height: calc(100vh - 158px);"
  >
    <div class="card-body font-size-lg">
      <div class="row">
        <div
          v-for="(entry, index) in activeTypes"
          :key="index"
          class="col text-center border-bottom"
          :class="activeFileType === entry.name ? '' : ''"
        >
          <b-link
            v-if="entry.name === textTemplateType || entry.showAlways"
            :class="
              activeFileType === entry.name ? 'text-primary' : 'text-muted'
            "
            :style="entry.style"
            @click="activeFileType = entry.name"
            >{{ entry.label }}
          </b-link>
        </div>
      </div>
      <perfect-scrollbar class="scroll h-100 row p-4">
        <div class="helpers">
          <div
            v-for="(entry, index) in activeTypes"
            :key="index"
            class="section"
          >
            <HelperSection
              v-if="activeFileType === entry.name"
              :sections="entry.sections"
            ></HelperSection>

          </div>
        </div>
      </perfect-scrollbar>
    </div>
  </div>
</template>

<style lang="scss">
.helper-section {
  i {
    color: #5e6278;
    margin-right: 8px;
  }
  ul.helper-section-list {
    list-style-type: none;
    padding-left: 0;

    li {
      cursor: pointer;

      &:hover {
        color: #ff3553;
        i {
          color: #ff3553;
        }
      }
    }
  }
}
</style>

<script>
export default {
  components: {
    HelperSection: () =>
      import("@/components/Projects/TextTemplates/Editor/HelperSection")
  },
  props: {
    textTemplateType: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      activeFileType: "twig",
      fileTypes: [
        {
          name: "twig",
          label: "Twig",
          style: "",
          showAlways: true,
          sections: [
            {
              title: "Hilfsfunktionen",
              operators: [
                {
                  title: "Bedingung",
                  template: "{% if ____ %}{% endif %}"
                },
                {
                  title: "Schleife",
                  template: "{% for key, entry in ____ %}"
                },
                {
                  title: "Variable",
                  template: "{% set ____ = ____ %}"
                },
                {
                  title: "Ausgabe",
                  template: "{{ ____ }}"
                }
              ]
            },
            {
              title: "Text-Operatoren",
              operators: [
                {
                  title: "Text ersetzen",
                  template: "|replace({'____': '____'})"
                },
                {
                  title: "Text maskieren",
                  template: "|e('html')"
                },
                {
                  title: "Text zu Kleinschreibung",
                  template: "|lower"
                },
                {
                  title: "Text zu Großschreibung",
                  template: "|upper"
                },
                {
                  title: "Trim",
                  template: "|trim"
                },
                {
                  title: "HTML Tags entfernen",
                  template: "|striptags"
                },
                {
                  title: "URL encodieren",
                  template: "|url_encode"
                },
                {
                  title: "Datum formatieren",
                  template: "|date('m/d/Y')"
                }
              ]
            },
            {
              title: "Nummern-Operatoren",
              operators: [
                {
                  title: "Nummer formatieren",
                  template: "|number_format(2, '.', ',')"
                },
                {
                  title: "Nummer runden",
                  template: "|round(2)"
                },
                {
                  title: "Betrag",
                  template: "|abs"
                }
              ]
            },
            {
              title: "Listen-Operatoren",
              operators: [
                {
                  title: "Erstes Element",
                  template: "|first"
                },
                {
                  title: "Letztes Element",
                  template: "|last"
                },
                {
                  title: "Länge der Liste",
                  template: "|length"
                },
                {
                  title: "Verknüpfung zu Text",
                  template: "|join(' ')"
                },
                {
                  title: "Verknüpfung zweier Listen",
                  template: "|merge(____)"
                },
                {
                  title: "Teilliste",
                  template: "|slice(____,____)"
                }
              ]
            },
            {
              title: "Sonstige Operatoren",
              operators: [
                {
                  title: "Ländername",
                  template: "|country_name"
                },
                {
                  title: "Währungsname",
                  template: "|currency_name"
                },
                {
                  title: "Währungszeichen",
                  template: "|currency_symbol"
                },
                {
                  title: "Standardwert",
                  template: "|default(____)"
                },
                {
                  title: "JSON Encode",
                  template: "|json_encode()"
                }
              ]
            }
          ]
        },
        {
          name: "xml",
          label: "XML",
          style: "",
          sections: [
            {
              title: "Hilfsfunktionen",
              operators: [
                {
                  title: "XML-Header",
                  template:
                    '<?xml version="1.0" encoding="utf-8" standalone="yes"?>'
                },
                {
                  title: "Element",
                  template: "<____></____>"
                }
              ]
            }
          ]
        },
        {
          name: "edifact",
          label: "EDIFACT",
          style: ""
        },
        {
          name: "html",
          label: "HTML",
          style: "",
          sections: [
            {
              title: "Hilfsfunktionen",
              operators: [
                {
                  title: "HTML-Gerüst",
                  template:
                    "<!doctype html>\n" +
                    "\n" +
                    '<html lang="en">\n' +
                    "<head>\n" +
                    '  <meta charset="utf-8">\n' +
                    '  <meta name="viewport" content="width=device-width, initial-scale=1">\n' +
                    "\n" +
                    "  <title>yedi TextTemplate</title>\n" +
                    '  <meta name="description" content="">\n' +
                    '  <meta name="author" content="yedi">\n' +
                    "\n" +
                    '  <link rel="icon" href="/favicon.ico">\n' +
                    '  <link rel="icon" href="/favicon.svg" type="image/svg+xml">\n' +
                    '  <link rel="apple-touch-icon" href="/apple-touch-icon.png">\n' +
                    "\n" +
                    "</head>\n" +
                    "\n" +
                    "<body>\n" +
                    "</body>\n" +
                    "</html>"
                }
              ]
            }
          ]
        }
      ]
    };
  },
  computed: {
    activeTypes() {
      return this.fileTypes.filter(type => {
        return type.name === this.textTemplateType || type.showAlways;
      });
    }
  }
};
</script>
